html, body {
  height: 100%;
  background-color: #F4F6F8;
}

.main-container {
  padding-bottom: 80px;
}

.vh-85 {
  height: 85vh;
}

.menubg{
  background-color: #ffffff;
}
